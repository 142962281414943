@import url(https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

.decor {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #372214;
}

.countdown {
  font-family: "Tangerine", cursive;
  font-size: 5rem;
  font-weight: 700;
  color: #fcd774;
}

.note {
  font-size: 2rem;
  padding: 2rem;
  color: white;
}

.image-gallery-image {
  max-height: 65vh !important;
}

@media screen and (max-width: 480px) {
  .countdown {
    padding: 1rem;
    font-size: 3rem;
  }

  .note {
    font-size: 1.2rem;
  }
}

.Welcome_top__1DOxh {
  background-color: #95389e;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Welcome_content__dxJ4f {
  font-size: 6rem;
  font-weight: 700;
  color: white;
  text-align: center;
}

.Welcome_emoji__3hVCo {
  font-size: 6rem;
}

@media screen and (max-width: 850px) {
  .Welcome_content__dxJ4f {
    font-size: 8rem;
    padding: 0.5rem;
  }

  .Welcome_emoji__3hVCo {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 500px) {
  .Welcome_content__dxJ4f {
    font-size: 5rem;
    padding: 0.5rem;
  }

  .Welcome_emoji__3hVCo {
    font-size: 3.5rem;
  }
}

.Album_container__3V24S {
  height: 100vh;
  padding: 1rem;
}

.Album_content__2V5oR {
  font-size: 2rem;
  margin: 1.5rem auto 0;
  text-align: center;
}

.Album_frameContainer__2TIO7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 2rem - 1.5rem);
}

.Album_frame__2EyA8 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  background-image: url(/static/media/bg.fd5dc79f.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.8rem;
  max-width: 40rem;
  margin: 0 auto;
  width: 90vw;
  padding: 2rem;
}

@media screen and (max-width: 500px) {
  .Album_content__2V5oR {
    font-size: 1.5rem;
  }
}

.Gifts_container__9kqTG {
  background-color: #95389e;
  height: 100vh;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.Gifts_h1__bKdU2 {
  font: 1.25rem;
  font-weight: 400;
  text-align: center;
}

.Gifts_gifts__lBibg {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.Gifts_gift__2mncV {
  font-size: 6rem;
  transform: scale(1);
  transition: all ease-out 400ms;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 8.875rem;
  margin: 1rem;
}

.Gifts_gift__2mncV:hover {
  transform: scale(1.25);
}

.Gifts_content__1LGH3 {
  font-size: 1.1rem;
  margin-top: 1rem;
}

.Gifts_popup__2fc2k {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: white;
  border-radius: 0.8rem;
  padding: 2rem;
  overflow-y: auto;
  max-height: 95vh;
  width: 90vw;
}

.Gifts_close__2Sj27 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #95389e;
  color: white;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0.5rem;
  font-size: 1.25rem;
  cursor: pointer;
}

.Gifts_hide__2T_U6 {
  display: none;
}

.Letter_container__2D4mB {
  height: 100%;
  padding: 1rem;
  color: #6f4a8e;
  width: 100%;
}

.Letter_h1__gzfw8 {
  text-align: center;
}

.Letter_letter__2gdX- {
  font-size: 1.25rem;
  line-height: 1.3;
}

.Art_container__1Qh1r {
  height: 90vh;
  max-width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.Art_img__1ednH {
  height: calc(100% - 3rem);
  max-width: 100%;
}

.Art_text__fo_wK {
  color: black;
  text-align: center;
  margin: 1rem 0;
}

@media screen and (max-width: 850px) {
  .Art_content__1SzQs {
    font-size: 8rem;
    padding: 0.5rem;
  }

  .Art_emoji__3IXoJ {
    font-size: 4.5rem;
  }
}

.AtoZ_container__1yoXt {
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.AtoZ_h1__1HTcN {
  text-align: center;
}

.AtoZ_cards__389An {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.AtoZ_card__2Yhz5 {
  background-color: white;
  width: 8rem;
  height: 8rem;
  perspective: 12rem;
  padding: 0.5rem;
  margin: 0.5rem;
}

.AtoZ_cardInner__3jkZi {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.AtoZ_card__2Yhz5:hover .AtoZ_cardInner__3jkZi {
  transform: rotateY(180deg);
}

.AtoZ_cardFront__2iFxS,
.AtoZ_cardBack__2BY2d {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AtoZ_cardBack__2BY2d {
  transform: rotateY(180deg);
}

.Songs_container__s2tTd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: black;
}

.Songs_head__DqJsY {
  font-size: 1.2rem;
  margin: 0.5rem 0 2rem;
}

