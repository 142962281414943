@import url("https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

.decor {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #372214;
}

.countdown {
  font-family: "Tangerine", cursive;
  font-size: 5rem;
  font-weight: 700;
  color: #fcd774;
}

.note {
  font-size: 2rem;
  padding: 2rem;
  color: white;
}

.image-gallery-image {
  max-height: 65vh !important;
}

@media screen and (max-width: 480px) {
  .countdown {
    padding: 1rem;
    font-size: 3rem;
  }

  .note {
    font-size: 1.2rem;
  }
}
