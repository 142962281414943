.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: black;
}

.head {
  font-size: 1.2rem;
  margin: 0.5rem 0 2rem;
}
